// import {
//     HOSPITALS,
//     DOCTORS,
//     LABS,
//     INSURANCE,
//     PHARMACY,
//     DEPARTMENTS,
//     LAB_PACKAGES,
//     LAB_TEST
// } from '../../Configuration/constants';

export const BULK_ADD = 'Bulk Add';
export const ENTITY_SELECT = 'Select Entity';
export const SELECT_ENTITY = 'Select Entity';
export const LAB_PACKAGES_LABEL = 'Lab Packages';
export const LAB_TEST_LABEL = 'Lab Tests';

// export const ENTITYLIST = [
//     { type: HOSPITALS, label: HOSPITALS },
//     { type: DOCTORS, label: DOCTORS },
//     { type: LABS, label: LABS },
//     { type: INSURANCE, label: INSURANCE },
//     { type: PHARMACY, label: PHARMACY },
//     { type: DEPARTMENTS, label: DEPARTMENTS },
//     { type: LAB_PACKAGES, label: LAB_PACKAGES_LABEL },
//     { type: LAB_TEST, label: LAB_TEST_LABEL }
// ];

export const selectHrisType = {
    selectOptionList: [
        {
            value: "",
            label: `Normal Rater File`
        },
        {
            value: "feedback360Hris",
            label: '360 Feedback Rater File'
        },
        {
            value: "cohortHris",
            label: 'Cohort Rater File'
        }
    ]
}